import Script from "next/script";

export function GoogleTagManagerNextJs() {
  return (
    <>
      <Script type="text/partytown" strategy="worker">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER"]}');

          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env["NEXT_PUBLIC_GOOGLE_ANALYTICS"]}');
        `}
      </Script>

      <Script
        type="text/partytown"
        strategy="worker"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env["NEXT_PUBLIC_GOOGLE_ANALYTICS"]}`}
      />
    </>
  );
}

export default GoogleTagManagerNextJs;
