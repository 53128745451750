import { GoogleTagManagerNextJs } from "@with-nx/analytics";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";

declare const window: Window & {
  fbq?: any;
};

export function ThirdPartyScripts() {
  const isProduction = process.env.NODE_ENV === "production";
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      window.fbq("track", "PageView");
    };

    if (router) {
      router.events.on("routeChangeComplete", handleRouteChange);
    }

    return () => {
      if (router) {
        router.events.off("routeChangeComplete", handleRouteChange);
      }
    };
  }, [router]);

  if (!isProduction) {
    return null;
  }

  return (
    <>
      <GoogleTagManagerNextJs />
      <Script
        id="hotjar"
        type="text/partytown"
        strategy="worker"
        dangerouslySetInnerHTML={{
          __html: `
              (function(h, o, t, j, a, r) {
                id = 3506718;
                sv = 6;
                debug = false;
                h.hj =
                  h.hj ||
                  function() {
                    (h.hj.q = h.hj.q || []).push(arguments);
                  };
                h._hjSettings = { hjid: id, hjsv: sv, hjDebug: debug };
                h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                if(!document.querySelector(
                  'script[src*="' + h._scriptPath + '"]'
                )){
                  a = o.getElementsByTagName('head')[0];
                  r = o.createElement('script');
                  r.async = 1;
                  r.src = h._scriptPath;
                  a.appendChild(r);
                }
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
            `,
        }}
      />

      <Script
        id="metricool"
        type="text/partytown"
        strategy="worker"
        data-tag="metricool-script"
        dangerouslySetInnerHTML={{
          __html: `
              function loadScript(callback) {
                  var head = document.getElementsByTagName("head")[0];
                  var script = document.createElement("script");
                  
                  script.type = "text/javascript";
                  script.async = true;
                  script.defer = true;
                  script.src = "https://tracker.metricool.com/resources/be.js";
                  script.onreadystatechange = callback;
                  script.onload = callback;
                  
                  head.appendChild(script);
              }

              loadScript(function() {
                  beTracker.t({ hash: "df4ca4d68686a0eecbedb2b8e21183aa" });
              });
            `,
        }}
      />

      <Script
        id="facebook-pixel"
        strategy="worker"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s){
              if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=true;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              '/assets/libs/fbevents.js');

              fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
              fbq('track', 'PageView');
            `,
        }}
      />

      <Script
        type="text/partytown"
        strategy="worker"
        dangerouslySetInnerHTML={{
          __html: `
            (function() {
              var Sentry = {
                init: function(config) {
                  window.Sentry = Sentry;
                  window.Sentry.config = config;
                },
                captureException: function(err) {
                  console.error(err);
                },
                BrowserTracing: function() {},
                Replay: function(config) {
                  return {
                    name: 'Replay',
                    setupOnce: function() {},
                  };
                },
              };

              Sentry.init({
                dsn: 'https://e2d75e58f4bb44b2912d35a35af4d8b7@o347130.ingest.sentry.io/4505520931930112',
                tracesSampleRate: 0.05,
                debug: false,
                replaysOnErrorSampleRate: 1.0,
                replaysSessionSampleRate: 0,
                integrations: [
                  new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: true,
                  }),
                ],
              });
            })();
          `,
        }}
      />
    </>
  );
}

export default ThirdPartyScripts;
