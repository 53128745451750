import { NextRouter, useRouter } from "next/router";
import { useNoPrint } from "./usePrint";

import { CampaignHelper } from "./CampaignHelper";
import Database from "./Database";
import { useEffect } from "react";

const isDocument = () => typeof window !== "undefined";

const bootstrapCaching = () => {
  if (isDocument()) {
    Database.bootstrap();
  }
};

const bootstrapHelpscout = () => {
  if (isDocument()) {
    import("helpscout-beacon-react")
      .then((m) => m.inject(process.env["NEXT_PUBLIC_HELPSCOUT_BEACON_ID"]))
      .catch(() => console.log("💯", "[Failed] Helpscout"))
      .finally(() => console.log("💯", "Helpscout"));
  }
};

const bootstrapController = (router: NextRouter) => {
  if (isDocument() && router) {
    router.events.on("routeChangeComplete", () => {
      window.document.body.scrollTo(0, 0);
    });
  }
};

const bootstrapServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js").then(
      (registration) => {
        console.log("🤖", "Success", registration);
      },
      (error) => {
        console.error("🤖", "Error", error);
      }
    );
  } else {
    console.error("🤖", "Not Supported");
  }
};

const checkCampaign = (router: NextRouter) => {
  const code = router.query?.["campaign"];

  if (code) {
    CampaignHelper.store(code as string);
  }
};

export const useApplication = () => {
  const router = useRouter();

  useEffect(() => {
    const maintenance = process.env["NEXT_PUBLIC_MAINTENANCE"] === "true";

    console.log("🚧", "Maintenance", maintenance);
    console.log("🚧", process.env["NEXT_PUBLIC_MAINTENANCE"]);

    if (maintenance) {
      router.push("/maintenance");
      return;
    }

    bootstrapCaching();
    bootstrapController(router);
    bootstrapServiceWorker();

    const https = window.location.protocol === "https:";
    const development = process.env.NODE_ENV !== "production";
    const staging =
      [
        "broadwaymedia.com",
        "www.broadwaymedia.com",
        "lab.broadwaymedia.com",
      ].includes(window.location.hostname) === false;

    if (!development && !staging && https) {
      bootstrapHelpscout();
    }
  }, []);

  useEffect(() => {
    checkCampaign(router);
  }, [router]);

  const print = useNoPrint();

  return {
    print,
  };
};

export default useApplication;
