import "../styles/globals.css";
import "../styles/homepage.scss";
import "simple-effing-primitive-layout/dist/styles.min.css";

import { AuthGate, AuthProviderClass } from "@with-nx/auth";
import { StripeGate } from "@with-nx/ecommerce";
import { MobileProvider, useApplication } from "@with-nx/hooks-n-helpers";
import { RegionProvider } from "@with-nx/region";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import ThirdPartyScripts from "../components/w/ThirdPartyScripts";

const Toaster = dynamic(() => import("react-hot-toast").then((c) => c.Toaster));
const LazyReduxProvider = dynamic(() => import("../components/ReduxProviders"));

const CookiePolicyBanner = dynamic(() =>
  import("@with-nx/simple-ui/organisms").then((c) => c.CookiePolicyBanner)
);

export default function App({ Component, pageProps }: AppProps) {
  const application = useApplication();
  const development = process.env.NODE_ENV !== "production";

  return (
    <MobileProvider>
      <AuthProviderClass
        configuration={{
          development,
          host: true,
        }}
      >
        <AuthGate>
          <RegionProvider>
            <StripeGate>
              <LazyReduxProvider>
                <>
                  <Component {...pageProps} />
                  {application.print ? undefined : <CookiePolicyBanner />}
                </>
              </LazyReduxProvider>
            </StripeGate>
          </RegionProvider>
        </AuthGate>

        <Toaster
          toastOptions={{
            style: {
              border: "unset",
              padding: "16px",
              color: "white",
              background: "var(--foreground)",
            },
          }}
        />

        <ThirdPartyScripts />
      </AuthProviderClass>
    </MobileProvider>
  );
}
