import { Cookies } from "./Cookies";
import useService from "./useService";

export class CampaignHelper {
  static async attach(token?: string) {
    const campaigns: string[] = Cookies.get("campaigns")
      ? JSON.parse(Cookies.get("campaigns") as string)
      : [];

    if (campaigns && campaigns?.length) {
      const makeRequestToCMS = useService("cms", {
        timeout: 10_000,
      });

      Promise.all(
        campaigns.map(async (campaign) =>
          makeRequestToCMS(
            "POST",
            `/api/v1/user-campaign/${campaign}`,
            {},
            {
              Authorization: `Bearer ${token}`,
            }
          )
        )
      )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          Cookies.remove("campaigns");
        });
    }
  }

  static store(campaign: string) {
    let campaigns = [campaign];

    if (Cookies.get("campaigns")) {
      const stored = JSON.parse(Cookies.get("campaigns") as string);

      if (!stored.includes(campaign)) {
        campaigns = [...stored, campaign];
      }
    }

    Cookies.set("campaigns", JSON.stringify(campaigns));
  }
}
